const getters = {
  teachers: state => state.user.teachers,
  school: state => state.content.school,
  schools: state => state.content.schools,
  group: state => state.content.group,
  department: state => state.content.department,
  config: state => state.content.config,
  token: state => state.user.token,
  profile: state => state.user.profile,
  orgCategory: state => state.user.orgCategory,
  subjects: state => state.content.subjects,
  grades: state => state.content.grades,
  weeks: state => state.content.weeks,
  years: state => state.content.years,
  classes: state => state.content.classes,
  eClass: state => state.content.eClass,
  students: state => state.content.students,
  sStudents: state => state.content.sStudents,
  sLeads: state => state.content.sLeads,
  homeworks: state => state.content.homeworks,
  homework: state => state.content.homework,
  easyforkids: state => state.content.easyforkids,
  easyclass: state => state.content.easyclass,
  easycertificate: state => state.content.easycertificate,
  classReport: state => state.content.classReport,
  classSubmissions: state => state.content.classSubmissions,
  mission: state => state.content.mission,
  missionTemplate: state => state.content.missionTemplate,
  notifications: state => state.content.notifications,
  news: state => state.content.news,
  topics: state => state.discussion.topics,
  comments: state => state.discussion.comments,
  lessons: state => state.content.lessons,
  lesson: state => state.content.lesson,
  media: state => state.media,
  document: state => state.document,
  questionGroups: state => state.question.questionGroups,
  questions: state => state.question.questions,
  googleDrive: state => state.media.googleDrive,
  drive: state => state.media.drive,
  leaderPlans: state => state.content.leaderPlans,
  lessonPlans: state => state.content.lessonPlans,
  teacherPlans: state => state.content.teacherPlans,
  leaderPlan: state => state.content.leaderPlan,
  lessonPlan: state => state.content.lessonPlan,
  teacherPlan: state => state.content.teacherPlan,
  rtContest: state => state.rtcontest.contest,
  rtContests: state => state.rtcontest.contests,
  rtQuestions: state => state.rtcontest.questions,
  rtTotalPoint: state => state.rtcontest.totalPoint,
  rtContestRankings: state => state.rtcontest.contestRankings,
  rcvContest: state => state.rcvContest.contest,
  rcvContests: state => state.rcvContest.contests,
  rcvQuestions: state => state.rcvContest.questions,
  rcvTotalPoint: state => state.rcvContest.totalPoint,
  rcvContestParticipants: state => state.rcvContest.participants,
  't-rcv-contests': state => state['t-rcv-contest'].contests,
  't-rcv-contest': state => state['t-rcv-contest'].contest,
  documentSubjects: state => state.document.subjects,
  house: state => state['mentor-house'].house,
  mentors: state => state['mentor-house'].mentors,
  easyMentorAccessToken: state => state['mentor-house'].easyMentorAccessToken
}

export default getters
